
.iFooter {
    width: 100%;min-width: 220px; 
    background-color: rgb(84, 9, 220);
    z-index: 10000;
  }
  .iFooter-column {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    width: 100%;min-width: 250px;
    padding: 1rem;
    text-align: center;
    justify-content: center; 
  }
  .iFooter-row {
    display: flex;
    flex-wrap: wrap;
    padding: 1rem;
    text-align: center;
    justify-content: center;
  }
  .iFooter-mark img {
    background-repeat: no-repeat;
    background-position: 0 0 ;
    width: 25px;
    height: 25px;
  }
  .iFooter-content { 
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    width: 100%;
    height: auto;
    padding: 1rem; 
  }
  .iFooter-content-l { 
    display: flex; 
    flex-direction: column;
    width: 47%;
    height: auto;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center; 
  }
  .iFooter-content-r { 
    display: flex; 
    flex-direction: row;
    width: 47%;
    height: auto;
    justify-content: center;
    align-items: center; 
  }
  .menu-link {
    position: relative;
    width: 200px;min-width: 200px;
    height: auto;
    text-align: left; 
  }
  .menu-media {
    display: flex;
    width: auto;
    height: auto;
    margin: 5px; 
  }
  .iFooter-pie { 
    display: flex;
    flex-direction: row;
    width: 100%;
    height: auto; 
  }
  .iFooter-pie-l { 
    display: flex;
    width: 47%;
    height: auto; 
  }
  .iFooter-pie-r { 
    display: flex;
    width: 47%;
    height: auto;
    align-items: center;
    justify-content: right; 
  }

/*== Estylos Madia Query max-820 ==*/
@media screen and (max-width: 760px){
    .iFooter-pie { 
        display: flex;
        flex-direction: column;
        width: 100%;min-width: 220px;
        height: auto; 
      }
      .iFooter-pie-l { 
        display: flex;
        width: 100%;
        height: auto; 
      }
      .iFooter-pie-r { 
        display: flex;
        width: 100%;
        height: auto;
        align-items: center;
        justify-content: right; 
      }
      .iFooter-content { 
        display: flex;
        flex-direction: column;
        flex-wrap: wrap;
        width: 100%;
        height: auto;
        padding: 1rem; 
      }
      .iFooter-content-l { 
        display: flex; 
        flex-direction: column;
        width: 100%;
        height: auto;
        flex-wrap: wrap;
        justify-content: center;
        align-items: center; 
      }
      .iFooter-content-r { 
        display: flex; 
        flex-direction: row;
        width: 100%;
        height: auto;
        justify-content: center;
        align-items: center; 
      }
}
