@font-face {
  font-family: 'Ttnorms';
  src: url('./assets/fonts/TTNorms-Regular.otf') format('opentype');
  font-weight: normal;
  font-style: normal;
}
/* Importa la fuente negrita si es necesario */
@font-face {
  font-family: 'BTtnorms';
  src: url('./assets/fonts/TTNorms-Bold.otf') format('opentype');
  font-weight: bold;
  font-style: normal;
}
@font-face {
  font-family: 'XBTtnorms';
  src: url('./assets/fonts/TTNorms-ExtraBold.otf') format('opentype');
  font-weight: bold;
  font-style: normal;
}
/* Importa la fuente Media si es necesario */
@font-face {
  font-family: 'MTtnorms';
  src: url('./assets/fonts/TTNorms-Medium.otf') format('opentype');
  font-weight: bold;
  font-style: normal;
}

body { 
  font-family:'TTNorms-Regular', sans-serif; 
  width: 100%;
  height: 100%;
  background-color: #e5e5f0; 
  margin: 0 auto;
  overflow-x: hidden;
}
/* == Estylos de Textos == */
.text-10-b{ font-size: 10px; font-weight: bold; font-family: 'TTNorms-Regular', sans-serif; text-decoration:none !important; }
.text-10-s{ font-size: 10px; font-weight: normal; font-family: 'TTNorms-Regular', sans-serif; text-decoration:none !important; }
.text-12-b{ font-size: 12px; font-weight: bold; font-family: 'TTNorms-Regular', sans-serif; text-decoration:none !important; }
.text-12-s{ font-size: 12px; font-weight: normal; font-family: 'TTNorms-Regular', sans-serif; text-decoration:none !important; }
.text-14-b{ font-size: 14px; font-weight: bold; font-family: 'TTNorms-Regular', sans-serif; text-decoration:none !important; }
.text-14-s{ font-size: 14px; font-weight: normal; font-family: 'TTNorms-Regular', sans-serif; text-decoration:none !important; }
.text-18-b{ font-size: 18px; font-weight: bold; font-family: 'TTNorms-Regular', sans-serif; text-decoration:none !important; }
.text-18-s{ font-size: 18px; font-weight: normal; font-family: 'TTNorms-Regular', sans-serif; text-decoration:none !important; }
.text-20-b{ font-size: 20px; font-weight: bold; font-family: 'TTNorms-Regular', sans-serif; text-decoration:none !important; }
.text-20-s{ font-size: 20px; font-weight: normal; font-family: 'TTNorms-Regular', sans-serif; text-decoration:none !important; }
.text-24-b{ font-size: 24px; font-weight: bold; font-family: 'TTNorms-Regular', sans-serif; text-decoration:none !important; }
.text-24-s{ font-size: 24px; font-weight: normal; font-family: 'TTNorms-Regular', sans-serif; text-decoration:none !important; }
.text-30-b{ font-size: 30px; font-weight: bold; font-family: 'TTNorms-Regular', sans-serif; text-decoration:none !important; }
.text-30-s{ font-size: 30px; font-weight: normal; font-family: 'TTNorms-Regular', sans-serif; text-decoration:none !important; }
.hw-title-import{ color: #fff; font-weight: bold; font-family: 'TTNorms-Regular', sans-serif; text-decoration:none !important; }

.title-h1-s { font-weight: normal; font-family: 'TTNorms-Regular', sans-serif; text-decoration:none !important; }
.title-h1-b { font-weight: bold; font-family: 'TTNorms-Regular', sans-serif; text-decoration:none !important; }

.tbg-withe { color: rgb(252, 252, 252);}
.tbg-black { color: rgb(0, 0, 0);}.tbg-black2 { color: #292929; font-weight: bolder;}.tbg-black-5 { color: rgb(0, 0, 0, 0.5);}.tbg-black-7 { color: rgb(0, 0, 0, 0.7);}.tbg-black-9 { color: rgb(0, 0, 0, 0.9);}
.tbg-gris { color: rgb(204, 204, 204);}.tbg-grey-5 { color: rgb(204, 204, 204, 0.5);}.tbg-grey-7 { color: rgb(204, 204, 204, 0.7);}.tbg-grey-9 { color: rgb(204, 204, 204, 0.9);}
.tbg-acua { color: #079cb6;}
.tbg-acua-o { color: hwb(204 0% 69%);}.tbg-acua-bo { font-weight: bold; color: #003050;}
.tbg-orange { color: rgb(252, 105, 5);}
.tbg-gris-c { color: #424242;}
.tbg-gris-o { color: #292828;}
.tbg-gris-ro { color: #5d0202;}
.tbg-red { color: #ff0000;}

.link-back-w {color: #fff; outline: none;text-decoration: none;}
.link-back-w a{color: #fff; outline: none;text-decoration: none;}
.link-back-w a:link {  color: #fff;}
.link-back-w a:visited {  color: #fff;}
.link-back-w a:hover { color: #fff; }
.link-back-w a:active {  color: #fff; }

.link-back-g {color: #131722; outline: none; font-size: 14px; font-weight: bold; text-decoration:none !important; font-family: Montserrat,"Open Sans",sans-serif;}
.link-back-g a{color: #131722; outline: none;text-decoration: none;}
.link-back-g a:link {  color: #131722;}
.link-back-g a:visited {  color: #131722;}
.link-back-g a:hover { color: #131722; }
.link-back-g a:active {  color: #131722;}

/* == Estylos de Links == */
.link-back {font-size: 14px;font-weight: bold;display: inline-block;padding: 5px 5px; outline: none;text-decoration: none; cursor: pointer;}
.link-back-12 {font-size: 12px;font-weight: bold;display: inline-block;padding: 5px 5px; outline: none;text-decoration: none; cursor: pointer;}

.link-sub-12ws { color: #424242;font-size: 12px;font-weight: bold;display: inline-block;padding: 5px 5px;}
.link-sub-12ws a {color: #424242;outline: none;text-decoration: none;}
.link-sub-12ws a:link {  color: #424242;}
.link-sub-12ws a:visited {  color: #424242;}
.link-sub-12ws a:focus { padding-bottom: 3px; }
.link-sub-12ws a:hover { color: #424242; padding-bottom: 3px; }
.link-sub-12ws a:active {  color: #424242;}

.link-SRute, .link-SRute a, .link-SRute a:link, .link-SRute a:visited, .link-SRute a:focus, .link-SRute a:hover, .link-SRute a:active { 
  color: #424242; font-size: 14px;font-weight: normal; outline: none;text-decoration: none;
}
.link-BRute, .link-BRute a, .link-BRute a:link, .link-BRute a:visited, .link-BRute a:focus, .link-BRute a:hover, .link-BRute a:active { 
  color: #424242; font-size: 14px;font-weight: bold; outline: none;text-decoration: none;
}
.TextLinks-BRute { font-size: 14px;font-weight: normal; font-family: 'TTNorms-Regular', sans-serif; text-decoration:none !important; }

.link-sub-12wo {font-size: 20px;font-weight: bold;display: inline-block;padding: 5px 5px;}
.link-sub-12wo a {color: #000;outline: none;text-decoration: none;}
.link-sub-12wo a:link {  color: #079cb6;}
.link-sub-12wo a:visited {  color: #003050;}
.link-sub-12wo a:focus { padding-bottom: 3px; }
.link-sub-12wo a:hover { color: #079cb6; padding-bottom: 3px; }
.link-sub-12wo a:active {  color: #003050;}

.link-mppl {font-size: 20px;font-weight: bold;}
.link-mppl a {color: #fff;outline: none;text-decoration: none;}
.link-mppl a:link {  color: #079cb6;}
.link-mppl a:visited {  color: #003050;}
.link-mppl a:focus { padding-bottom: 3px; }
.link-mppl a:hover { color: #079cb6; padding-bottom: 3px; }
.link-mppl a:active {  color: #003050;}
/* == Estylos de fondos background == */
.bg-withe { background-color: rgb(252, 252, 252);}
.bg-black-n { background-color: rgb(0, 0, 0)}
.bg-gris-n { background-color: rgb(204, 204, 204, 0.7);}
.bg-acua-n { background-color: rgb(7, 156, 182);}
.bg-orange-n { background-color: rgb(252, 105, 5);}
.bg-gris-c { background-color: #424242;}
.bg-gris-o { background-color: #292828;}
/*** Diseño fondo de body gradiente para Login y paginas principales ***/
.Background-Element { 
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  background-image: url("https://cloud.viwhite.com/images/Background.png");
  background-position: center center;
  background-repeat: no-repeat;  
  background-size: cover;
  transition: background 0.3s, border-radius 0.3s, opacity 0.3s; 
}
.Background-MElement { 
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  background-image: url("https://cloud.viwhite.com/images/MBackground.png");
  background-position: center center;
  background-repeat: no-repeat; 
  background-size: cover;
  transition: background 0.3s, border-radius 0.3s, opacity 0.3s; 
}

.ToiVRute {
  position: relative;
  display: flex;
  width:auto;
  height: 40px; 
  margin-top: 3rem; 
  padding: 1rem;
  overflow: hidden;
  z-index: 10;
}
.BSessionSale {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 90%;
  height: auto;
  padding: 1rem;
  margin: 5px auto;  
} 
/* Stylos de Sistema Registro  */
code {  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',    monospace;}
.primary_color {  color: #bc6aff}
.noneDecoration {  text-decoration: none !important;}
.link_text { padding-left: 1rem; text-transform: uppercase; font-weight: 600; color: #ffffff;}
.App { ext-align: center;}
.App-logo { height: 40vmin; pointer-events: none;}
@media (prefers-reduced-motion: no-preference) {  .App-logo { animation: App-logo-spin infinite 20s linear; } }
.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}
.App-link {  color: #61dafb;}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}